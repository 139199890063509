import React, { FC } from 'react';
import { useExperiments, useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import Experiments from '@wix/wix-experiments';

import { FieldGroup } from '../../FieldGroup';
import { SectionHeader } from '../../common/SectionHeader';
import { classes, st } from './PublicInfo.st.css';
import { useFields } from '../../../../../contexts/widget';
import settingsParams from '../../../settingsParams';
import { Experiment, FieldSection } from '../../../../../types';
import { AvatarInput } from '../../common/AvatarInput';

type Settings = ReturnType<typeof useSettings>;

const MEMBER_FORMIK_FIELD_SELECTOR = 'member';

const getIsAvatarVisible = (settings: Settings, experiments: Experiments) => {
  const experiment = experiments.enabled(Experiment.ProfileCardNewLayouts);
  const setting = settings.get(settingsParams.avatarVisibility) ?? false;

  return experiment && setting;
};

export const PublicInfo: FC = () => {
  const fields = useFields();
  const settings = useSettings();
  const { experiments } = useExperiments();
  const { isMobile } = useEnvironment();

  const isAvatarVisible = getIsAvatarVisible(settings, experiments);

  return (
    <div className={classes.root}>
      <SectionHeader
        title={settings.get(settingsParams.publicInfoSectionTitle)}
        subtitle={settings.get(settingsParams.publicInfoSectionSubtitle)}
      />
      <div
        className={st(classes.fieldGroup, {
          withAvatar: isAvatarVisible,
          mobile: isMobile,
        })}
      >
        <FieldGroup
          fields={fields}
          section={FieldSection.DISPLAY_INFO}
          isSingleColumn={isAvatarVisible}
        />
        {isAvatarVisible && (
          <AvatarInput formikFieldSelector={MEMBER_FORMIK_FIELD_SELECTOR} />
        )}
      </div>
    </div>
  );
};
